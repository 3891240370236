<template>
  <div class="container">
    <edit-header
      entity="referral"
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :isNew="isNew">
    </edit-header>
    <form
      v-disable-all="(isNew && !can(uiPermissions.REFERRAL_CREATE)) || (!isNew && !can(uiPermissions.REFERRAL_UPDATE))"
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div class="row">
        <div class="col">
          <div data-test="card-referral" class="card">
            <div class="card-header">
              <h3 class="card-title">Referral</h3>
              <div class="card-options">
                <label data-test="toggle-has-been-used" class="custom-switch m-0">
                  <input
                    v-model="item.used"
                    type="checkbox"
                    class="custom-switch-input">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">Has been used</span>
                </label>
              </div>
            </div>
            <div class="card-body">
              <div data-test="group-customer" class="form-group">
                <label class="form-label">Customer</label>
                <user-selector v-model="item.customer" :required="true"></user-selector>
              </div>
              <div class="form-group">
                <label class="form-label">Amount</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">AED</span>
                  </div>
                  <input
                    v-model="item.amount"
                    type="number"
                    data-test="txt-amount"
                    class="form-control"
                    required
                    min="0"/>
                </div>
              </div>
              <div data-test="group-referred-customer" class="form-group">
                <label class="form-label">Referred customer</label>
                <user-selector v-model="item.referredCustomer" :required="true"></user-selector>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="referral"
            :isNew="isNew"
            :submitting="submitting"
            hide-delete="true">
          </edit-footer>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import UserSelector from '@/components/UserSelector';
import edit from '@/mixins/edit';
import {referrals} from '@/services';

export default {
  components: {
    EditFooter,
    EditHeader,
    UserSelector,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      item: {
        amount: 1,
        customer: null,
        referredCustomer: '',
        used: false,
      },
    };
  },
  computed: {
    route() {
      return '/referrals';
    },
  },
  methods: {
    fetchData(id) {
      return referrals.getById(id);
    },
    submitData(item) {
      const payload = Object.assign({}, item, {
        amount: Number(item.amount),
        customer: item.customer.id,
        email: item.referredCustomer.user.email,
        referredCustomer: item.referredCustomer.id,
      });
      return referrals.manual(payload);
    },
  },
};

</script>
